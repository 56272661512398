<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <!-- TAB INPUTS -->
        <input id="tab-1" class="page-tab-input" name="page-tab-input" type="radio" checked />
        <input id="tab-2" class="page-tab-input" name="page-tab-input" type="radio" checked />

        <!-- TABS CONTAINER -->
        <div class="user-settings-header page-tabs-container">
            <label class="page-tab" for="tab-1">Discharge Report</label>
            <label class="page-tab" for="tab-2">Client's Note History</label>
        </div>

        <!-- CONTENT -->

        <section v-if="!loading" class="rec-data-div tabbed-page" :class="component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>
                <hr class="m-15" />

                <!-- put generated code BELOW here -->
                {{ date }}
                <div class="dark-text header-w-bg">Discharge Report</div>
                <Radios v-model="rec.transfer_summary" label="Is this a transfer summary?" :options="['No', 'Yes']" />
                <Radios v-model="rec.mandated_client" label="Is this a mandated client?" :options="['No', 'Yes']" />
                <Input type="text" v-model="rec.pacts_number" label="PACTS#" v-if="rec.mandated_client === 'Yes'" />
                <Input type="text" v-model="rec.dc_number" label="DC#" v-if="rec.mandated_client === 'Yes'" />
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Diagnosis at Discharge</div>
                    <Radios v-model="rec.diagnosis_at_discharge" label="" :options="['']" />
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addDiagnosis()">
                        <span class="material-icons purple">add_box</span>Add diagnosis
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Inital Presenting Problem</div>
                    <Input type="text" v-model="rec.initial_presenting_problem" label="" />
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Other Agencies Involved</div>
                    <div v-for="(agency, idx) in rec.agency" :key="idx">
                        <Input type="text" v-model="rec.agency[idx].agency_name" label="Agency Name" />
                        <Input type="text" v-model="rec.agency[idx].agency_plan" label="Plan" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addAgency()">
                        <span class="material-icons purple">add_box</span>Add Agency
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Session Types Provided</div>
                    <Checkbox v-model="rec.individual" label="Individual" />
                    <Checkbox v-model="rec.group" label="Group" />
                    <Checkbox v-model="rec.couple" label="Couple" />
                    <Checkbox v-model="rec.family" label="Family" />
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Reason for Discharge</div>
                    <Input type="text" v-model="rec.reason_for_discharge" label="" />
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Progress Made During Treatment</div>
                    <TextArea type="text" v-model="rec.progress_during_treatment" label="" />
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Substance Abuse Details</div>
                    <p>Primary</p>
                    <div v-for="(sub_abb, idx) in rec.substance_abuses" :key="'sub_abb' + idx">
                        <Input type="text" v-model="rec.substance_abuses[idx].substance" label="Substance" />
                        <Input type="text" v-model="rec.substance_abuses[idx].start_age" label="Start Age" />
                        <Input type="text" v-model="rec.substance_abuses[idx].frequency" label="Frequency of Past Use" />
                        <Input type="text" v-model="rec.substance_abuses[idx].recent" label="Frequency in Past 90 Days" />
                        <Input type="date" v-model="rec.substance_abuses[idx].last_use" label="Approximate Last Use" />
                        <Input type="text" v-model="rec.substance_abuses[idx].method" label="Method" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addSubstance()">
                        <span class="material-icons purple">add_box</span> Add substance
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Toxicology Report Results</div>
                    <div class="bottom-15" v-for="(tox_repo, idx) in rec.drug_screenings" :key="'tox_repo' + idx">
                        <Input type="date" v-model="rec.drug_screenings[idx].screening_date" label="Screening Date" />
                        <dropdown
                            v-model="rec.drug_screenings[idx].screening_result"
                            label="Result"
                            :options="['Positive', 'Negative']"
                        />
                        <Input
                            v-if="rec.drug_screenings[idx].screening_result === 'Positive'"
                            type="text"
                            v-model="rec.drug_screenings[idx].screening_substances"
                            label="Substances Detected"
                        />
                    </div>
                    <button class="add-service_btn no-bg dark-text add top-15" @click.prevent="addDrugScreening()">
                        <span class="material-icons purple">add_box</span>Add toxicology results
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Current Medications</div>
                    <div v-for="(medication, idx) in rec.current_medications" :key="'medications' + idx">
                        <Input type="text" v-model="rec.current_medications[idx]" label="" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addMedications()">
                        <span class="material-icons purple">add_box</span>Add medications
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Changes In Living Situation After Treatment</div>
                    <div v-for="(change, idx) in rec.living_changes" :key="'living_change' + idx">
                        <Input type="text" v-model="rec.living_changes[idx]" label="" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addLivingSituation()">
                        <span class="material-icons purple">add_box</span> Add detail
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Changes in Employment After Starting Treatment</div>
                    <div v-for="(change, idx) in rec.employment_changes" :key="'employment_changes' + idx">
                        <Input type="text" v-model="rec.employment_changes[idx]" label="" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addEmpChanges()">
                        <span class="material-icons purple">add_box</span> Add details
                    </button>
                </div>

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Referrals Suggested to Client During Treatment</div>
                    <div v-for="(referral, idx) in rec.referrals" :key="'referral' + idx">
                        <Input type="text" v-model="rec.referrals[idx]" label="" />
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addReferal()">
                        <span class="material-icons purple">add_box</span> Add referral
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Aftercare Plan</div>
                    <TextArea type="text" v-model="rec.aftercare_plan" label="" />
                </div>
                <button @click.prevent="log()">Log the rec</button>
                <!-- put generated code ABOVE here -->

                <div class="align-right">
                    <ConfirmButton @click="updateRecord()" :canedit="isEditable" />
                </div>
                <FormSignature
                    :user_id="user_id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </section>
        <section class="tabbed-page">
            <h1>Client's Note History</h1>
        </section>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    import { form_data } from '../../util/apiRequests';

    import dayjs from 'dayjs';

    export default {
        name: 'DischargeDetail',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                component_name: false,
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0
            };
        },
        methods: {
            addDiagnosis() {
            },
            addAgency() {
                this.rec.agency.push({ agency_name: '', agency_plan: '' });
            },
            addSubstance() {
                this.rec.substance_abuses.push({
                    recent: '',
                    last_use: '',
                    frequency: '',
                    method: '',
                    start_age: 0
                });
            },
            addMedications() {
                this.rec.current_medications.push('');
            },
            addLivingSituation() {
                this.rec.living_changes.push('');
            },
            addEmpChanges() {
                this.rec.employment_changes.push('');
            },
            addReferal() {
                this.rec.referrals.push('');
            },
            addDrugScreening() {
                this.rec.drug_screenings.push({
                    screening_result: '',
                    screening_date: '',
                    screening_substances: ''
                });
            },

            async fail2sign() {},
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async validateForm() {
                const isValid = 1; // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.');
                }

                return isValid;
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }
                    const isValid = await this.validateForm();
                    if (isValid) {
                        let xthis = await dryalex.form_data_update_record(this, e);
                        merge(this, xthis);
                    }
                }
            },
            async init() {
                const { data } = await this.$api(form_data.getTreatmentPlan(this.client_id));

                const treatmentPlan = data[0].raw_data;

                const defaultRec = {
                    group: 0,
                    agency: [{ agency_name: '', agency_plan: '' }],
                    couple: 0,
                    family: 0,
                    dc_number: '',
                    referrals: [''],
                    zenddummy: '',
                    individual: 0,
                    pacts_number: '',
                    aftercare_plan: '',
                    living_changes: [''],
                    drug_screenings: [{ screening_date: '', screening_result: '', screening_substances: '' }],
                    mandated_client: 'No',
                    substance_abuses: [
                        { method: '', recent: '', last_use: '', frequency: '', start_age: 0, substance: '' }
                    ],
                    transfer_summary: 'No',
                    employment_changes: [''],
                    current_medications: [''],
                    reason_for_discharge: '',
                    diagnosis_at_discharge: '',
                    progress_during_treatment: '',
                    initial_presenting_problem: ''
                };

                defaultRec.currentMedications = [treatmentPlan.medications];
                defaultRec.agency = [{ agency_name: treatmentPlan.agency, agency_plan: '' }];

                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
            }
        },
        async created() {
            await this.init();
        },
        computed: {
            date() {
                return dayjs().format('MM/DD/YYYY, HH:mm A');
            }
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
